@mixin black-button-with-icon($icon: null) {
  @include elc-button;
  @include elc-button--dark;
  &:visited {
    background-color: unset;
    border-color: unset;
  }
  &:focus {
    box-shadow: none;
  }
  @if $icon != null {
    &::before {
      content: '';
      mask: url('#{$base-theme-path}img/icons/src/#{$icon}');
      -webkit-mask: url('#{$base-theme-path}img/icons/src/#{$icon}');
      mask-size: cover;
      -webkit-mask-size: cover;
      background-color: $color-white;
      width: 15px;
      height: 15px;
      vertical-align: sub;
      display: inline-block;
      margin-right: 10px;
    }
  }
}

@mixin pr-label {
  display: block;
  font-family: $font--label;
  font-size: get-rem(12px);
  letter-spacing: 1.5px;
  line-height: get-line-height(12px, 15px);
  position: relative;
  text-transform: uppercase;
  z-index: 20;
}

@mixin pr-label-content {
  background-color: $color-white;
  bottom: -6px;
  display: inline-block;
  #{$ldirection}: 10px;
  padding: 0 6px;
  position: relative;
  font-family: $font--label;
  font-size: get-rem(12px);
}

@mixin pr-input {
  @include appearance(none);
  @include input-placeholder {
    color: $color-placeholder;
    text-transform: uppercase;
  }
  border: 1px solid $border-color-normal;
  color: $color-black;
  font-family: $font--text;
  font-size: get-rem(12px);
  line-height: 1;
  padding: 17px 15px 12px !important;
  transition: all 0.3s;
  width: 100%;
  border-radius: 0;
  box-shadow: none;
  &:hover {
    border-color: $color-gray;
  }
  &:focus {
    border-color: $color-black;
  }
  &.pr-invalid-field {
    border-color: $color-error;
  }
}

@mixin pr-select {
  @include appearance(none);
  @include swap_direction(padding, 17px 35px 12px 15px);
  background-color: $color-white;
  background-image: url('#{$base-theme-path}img/select-box/caret--down.svg');
  background-position: $rdirection 50%;
  background-repeat: no-repeat;
  border: 1px solid $color-black;
  color: $color-black;
  cursor: pointer;
  font-family: $font--select;
  font-size: get-rem(12px);
  line-height: 1;
  text-overflow: ellipsis;
  text-transform: none;
  width: 100%;
  &:focus {
    border-color: $color-black;
  }
  &[disabled] {
    color: $color-gray;
  }
  &:invalid {
    border-color: $color-light-gray;
    color: $color-placeholder;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    &:focus {
      text-transform: uppercase;
    }
  }
}

@mixin stars($size: 17px) {
  $pr-count: (0, 25, 50, 75, 100);
  @each $pr-value in $pr-count {
    .pr-star-v4-#{$pr-value}-filled {
      @if $pr-value == 0 {
        background: url('#{$base-theme-path}img/icons/src/star-yellow--outline.svg')
          no-repeat
          center
          transparent;
      } @else {
        background: url('#{$base-theme-path}img/icons/src/star-yellow.svg')
          no-repeat
          center
          transparent;
      }
      background-size: $size;
      width: $size;
      height: $size;
      @include swap_direction(margin, 0 2px 0 0);
      &:focus {
        box-shadow: 0 0 0 $color-white;
        -webkit-box-shadow: 0 0 0 $color-white;
        outline: 0;
      }
    }
  }
}

@mixin thumbs {
  .pr-helpful-no {
    padding-left: 10px !important;
  }
  .pr-helpful-yes,
  .pr-helpful-no {
    border: none;
    margin: 0;
    padding: 0;
    outline: 0;
    &:hover {
      background: none;
      span {
        color: $color-gray;
      }
      .pr-thumbs-icon svg path {
        fill: $color-gray;
      }
    }
    &:focus {
      box-shadow: none;
    }
    .pr-thumbs-icon {
      width: 26px;
      height: 26px;
      svg {
        width: 100%;
        height: 100%;
      }
    }
    &.pr-helpful-active {
      background: none !important;
      span {
        color: $color-gray;
      }
      .pr-thumbs-icon svg path {
        fill: $color-gray;
      }
    }
    .pr-helpful-count {
      font-family: $font--text;
      font-size: get-rem(13px);
      color: $color-black;
      vertical-align: baseline;
    }
  }
}

$percent-unit: unquote('%');

@mixin pw_stars($size: 17px) {
  $pr-count: (0, 25, 50, 75, 100);
  @each $pr-value in $pr-count {
    .pr-star-v4-#{$pr-value}-filled {
      background: url('#{$base-theme-path}img/icons/src/star-yellow--outline.svg')
        no-repeat
        center
        transparent;
      background-size: $size;
      width: $size;
      height: $size;
      position: relative;
      @include swap_direction(margin, 0 2px 0 0);
      &:focus {
        box-shadow: 0 0 0 $color-white;
        -webkit-box-shadow: 0 0 0 $color-white;
        outline: 0;
      }
      &::after {
        background: url('#{$base-theme-path}img/icons/src/star-yellow.svg') no-repeat;
        width: #{$pr-value}$percent-unit;
        content: '';
        height: $size;
        position: absolute;
        top: 1px;
        #{$ldirection}: 0;
        background-position: 0 0;
        background-size: $size;
      }
    }
  }
}
