@import '../../../../scss/theme-bootstrap';
@import '../../../../scss/standalone/ratings-reviews/ratings-reviews-common';

$ratingSize: 17px;
$ratingWidth: 85px;

// Prodcat rating component
.elc-rating-component {
  margin: 0 !important;
  .elc-product-rating-wrapper {
    position: relative;
    justify-content: flex-start !important;
    .elc-read-reviews-link {
      cursor: pointer;
      height: 20px;
      #{$ldirection}: 0;
      position: absolute;
      top: 0;
      width: 178px;
    }

    // Prodcat Stars
    .elc-stars-wrapper {
      margin-#{$rdirection}: 0 !important;
      .elc-stars-simplified:before,
      .elc-stars-simplified-stars:before {
        content: '';
        display: block;
        position: absolute;
        height: $ratingSize;
        width: $ratingWidth;
        top: 0;
        #{$sd-grid-2-up-selector} {
          height: 14px;
          width: 70px;
          @include breakpoint($landscape-up) {
            height: $ratingSize;
            width: $ratingWidth;
          }
        }
      }
      .elc-stars-simplified {
        position: relative;
        height: $ratingSize;
        width: $ratingWidth;
        #{$sd-grid-2-up-selector} {
          height: 14px;
          width: 70px;
          @include breakpoint($landscape-up) {
            height: $ratingSize;
            width: $ratingWidth;
          }
        }
        &::before {
          background: url('#{$base-theme-path}img/icons/src/star-yellow--outline.svg') repeat-x;
        }
        &-stars {
          display: block;
          position: absolute;
          overflow: hidden;
          left: 0;
          height: $ratingSize;
          width: $ratingWidth;
          #{$sd-grid-2-up-selector} {
            height: 14px;
            width: 70px;
            @include breakpoint($landscape-up) {
              height: $ratingSize;
              width: $ratingWidth;
            }
          }
          &::before {
            background-color: none !important;
            background: url('#{$base-theme-path}img/icons/src/star-yellow.svg') repeat-x;
          }
        }
      }
    }

    // Prodcat avg text
    .elc-average-rating-text {
      display: none !important;
    }

    // Prodcat ratings reviews
    .elc-rating-reviews {
      margin: 0 !important;
      position: relative;
      .elc-reviews-number {
        #{$sd-grid-2-up-selector} {
          font-size: get-rem(12px);
          @include breakpoint($landscape-up) {
            font-size: get-rem(14px);
          }
        }
        span {
          visibility: visible;
          font-family: $font--text;
          font-size: get-rem(14px);
          font-stretch: normal;
          font-style: normal;
          font-weight: bold;
          color: $color-black !important;
          margin: 0 2px;
          #{$sd-grid-2-up-selector} {
            font-size: get-rem(12px);
            @include breakpoint($landscape-up) {
              font-size: get-rem(14px);
            }
          }
        }
      }
    }
  }

  // R&R styles for button wrapper
  .elc-rating-button {
    background: none;
    border: none;
    color: unset;
    display: flex;
    font-family: unset;
    font-size: unset;
    height: auto;
    letter-spacing: unset;
    line-height: normal;
    padding: 0;
    text-transform: unset;
    width: auto;
    &:hover {
      background: none;
      color: $color-gray;
      transition: color 0.3s;
    }
    .elc-read-reviews {
      border: none;
      margin: 0;
      padding: 0;
    }
    .elc-stars-wrapper .elc-stars-simplified {
      height: 18px;
      #{$sd-grid-2-up-selector} {
        height: 14px;
        @include breakpoint($landscape-up) {
          height: 18px;
        }
      }
    }
  }
}

// Power reviews - Bar graph and rating star styles
.p-w-r {
  .pr-review-snapshot {
    background-color: $color-white;
    position: relative;
    font-family: $font--text-main;
    &.pr-review-enhanced {
      display: block;
      @media (min-width: $medium-up) {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        justify-content: center;
      }
      .pr-snippet-review-count,
      .pr-review-snapshot-block-headline,
      .pr-review-snapshot-block-pros,
      .pr-review-snapshot-block-cons,
      .pr-snippet-reco-to-friend-percent svg,
      .pr-review-snapshot-header.pr-review-snapshot-content-block .pr-review-snapshot-header-intro {
        display: none;
      }
      .pr-snippet-stars-container .pr-snippet-stars {
        @include pw_stars(30px);
        margin-bottom: 0;
      }
      .pr-histogram-stars:focus .pr-ratings-histogram-bar-container .pr-ratings-histogram-bar,
      .pr-ratings-histogram .pr-ratings-histogram-bar-container .pr-ratings-histogram-bar {
        background-color: transparent;
        border-radius: 0;
        height: 29px;
        border: 2px solid $color-light-yellow;
      }
      .pr-review-snapshot-block {
        width: 100%;
      }
      .pr-review-snapshot-block .pr-ratings-histogram-bar .pr-ratings-histogram-barValue {
        background: $color-light-yellow !important;
        height: 27px;
        border-radius: 0;
      }
      .pr-ratings-histogram .pr-histogram-count,
      .pr-review-snapshot-block .pr-histogram-label {
        font-size: 14px;
        text-transform: uppercase;
        color: $color-black !important;
        font-weight: normal;
      }
      .pr-review-snapshot-block .pr-histogram-label {
        font-family: $font--pretendard-semi-bold;
      }
      .pr-snippet-write-review-link {
        @include elc-button;
        @include elc-button--dark;
        @include black-button-with-icon('edit.svg');
        @media (min-width: $medium-up) {
          position: absolute;
          #{$rdirection}: 0;
          bottom: 0;
        }
      }
      .pr-review-snapshot-header,
      .pr-review-snapshot-block-container {
        width: 100%;
        @media (min-width: $medium-up) {
          width: 30%;
        }
      }
      .pr-review-snapshot-header.pr-review-snapshot-content-block {
        position: relative;
        padding-bottom: 50px;
        display: block !important;
      }
      .pr-snippet-stars-png {
        flex-direction: column;
      }
      .pr-review-snapshot-header .pr-snippet-stars-reco-reco .pr-snippet-reco-to-friend {
        display: block;
        text-align: center;
        margin: 0 auto;
        .pr-snippet-reco-to-friend-percent .pr-reco .pr-reco-value {
          color: $color-black;
          font-size: 14px;
          font-weight: bold;
          margin: 0;
          line-height: 1;
        }
        .pr-snippet-reco-to-friend-percent,
        .pr-reco-green,
        .pr-reco-value,
        .pr-reco-to-friend-message {
          display: inline !important;
          line-height: 1;
          color: $color-black;
          font-family: $font--pretendard-semi-bold;
          font-size: 14px;
          text-transform: uppercase;
          margin: 0;
        }
      }
      .pr-review-snapshot-header.pr-review-snapshot-content-block .pr-review-snapshot-snippets {
        .pr-snippet-stars-reco-inline .pr-snippet-stars-reco-stars .pr-snippet {
          .pr-snippet-read-and-write .pr-snippet-write-review-link {
            position: absolute;
            font-size: 12px !important;
            #{$ldirection}: 50%;
            transform: translateX(-50%);
            width: 200px;
            bottom: 0;
            @include black-button-with-icon('edit.svg');
          }
          .pr-snippet-stars-container .pr-snippet-stars .pr-snippet-rating-decimal {
            border: none;
            color: $color-black;
            width: auto;
            font-size: 40px;
            line-height: 1;
            margin: 10px 0;
            font-family: $font--pretendard-light;
            font-weight: normal;
            &::after {
              content: $reviews_suffix;
              color: $color-light-grey;
            }
          }
        }
      }
      .pr-snippet-stars-reco-inline .pr-snippet,
      .pr-snippet-stars-reco-inline,
      &.pr-snapshot-mobile
        .pr-review-snapshot-header.pr-review-snapshot-content-block
        .pr-review-snapshot-snippets
        .pr-snippet-stars-reco-inline {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
      }
    }
  }
}

.elc-reviews-wrapper {
  .pr-rd-review-disclosure {
    padding-top: 20px;
    @media (min-width: $medium-up) {
      padding-top: 0;
    }
  }
}
